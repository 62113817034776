import http from '../../../http/axiosConfig';
import mailTableBtnMixin from './mailViewBtnMixin';
export default {
  mixins: [mailTableBtnMixin],
  data() {
    return {
      search: '',
      tableViews: [],
    };
  },
  methods: {
    async getViews() {
      let page = this.pagination.page;
      let url = `View?page=${page}&limit=${this.pagination.rowsPerPage}&sortField=createAt&sortDesc=true`;
      let result = await http.get(url);
      let res = result.data.data ? result.data.data : result.data;
      let tableBody = [];
      if (res.length > 0) {
        res.forEach((inView) => {
          let createAt = this.convertDate(inView.createAt);
          let createBy =
            inView.createBy && inView.createBy.split('@').shift();

          let Created = `${createAt} (${createBy})`;

          let updateAt = inView.updateAt ? this.convertDate(inView.updateAt) : '';
          let updateBy =
            inView.updateBy ? inView.updateBy.split('@').shift() : '';

          let LastUpdated = updateAt && updateBy ? `${updateAt} (${updateBy})` : '';

          tableBody.push({
            ...inView,
            Created: Created,
            LastUpdated: LastUpdated,
          });
        });
      }
      this.tableViews = tableBody;
      this.$forceUpdate();
      //console.log(this.tableViews);
    },
    async postViewJson() {
      let ViewNew = this.viewNew;
      try {
        let res = await http.post(`View`, ViewNew);
        (res.success || res.data.success) ? this.notifySuccess('View Cadastrada!') : this.notifyError(res);

        this.getViews();
        this.$forceUpdate();
        window.location.reload();
        return this.handleClose();
      } catch (error) {

        this.notifyError(error);
      }

    },
    async postViewRun(item) {
      var run = {
        "params": item.parameters,
        "viewId": item.id,
        "page": 1,
        "limit": 3000000
      };
      //let ViewNew = this.viewNew;
      console.log("viewNew:", run);
      try {
        let res = await http.post(`View/run`, run);
        (res.success || res.data.success) ? this.notifySuccess('View executada com sucesso') : this.notifyError(res);
        this.jsonstr = res.data.data.data;
        this.$forceUpdate();

      } catch (error) {
        this.notifyError(error);
      }
    },
    async putViewJson(obj) {
      console.log(obj);
      let stuffEdit = obj;
      let itemId = stuffEdit.id;
      try {
        let res = await http.put(`View/${itemId}`, stuffEdit);
        console.log(res);
        (res.success || res.data.success) ? this.notifySuccess('View Alterada!') : this.notifyError(res);

        this.getViews();
        this.$forceUpdate();
        return this.handleClose();
      } catch (error) {
        this.notifyError(error);
      }

    },
    async deleteView(item) {
      let itemId = item.id;
      return await http.delete(`View/${itemId}`);
    },
  },
};