<template>
    <div>
        <v-text-field
        outlined
        v-model="search"
        class="mb-5"
        append-icon="mdi-find-replace"
        label="Pesquisar"
        single-line
        hide-details
        ></v-text-field>
        
        <v-col class="text-right">
            <v-btn color="secondary" @click="newAnalysy()">Nova Análise</v-btn>
        </v-col>

        <v-data-table
            :headers="headers"
            :items="items"
            hide-default-footer
            class="elevation-1"
            :search="search"
            :key="key"
        >
        <template v-slot:item.actions="{ item }">
            <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="secondary" icon v-bind="attrs" v-on="on">
                <v-icon>mdi-menu - {{ item }}</v-icon>
                </v-btn>
            </template>
            <v-list>
                <v-list-item link @click="updateAnalysis(item, true)">
                <v-list-item-icon>
                    <v-icon color="secondary" v-text="'mdi-open-in-new'"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title v-text="'Visualizar'"></v-list-item-title>
                </v-list-item-content>
                </v-list-item>
                <v-list-item link @click="updateAnalysis(item, false)">
                <v-list-item-icon>
                    <v-icon color="secondary" v-text="'mdi-code-json'"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title v-text="'Editar'"></v-list-item-title>
                </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item link>
                <v-list-item-icon>
                    <v-icon
                    color="secondary"
                    v-text="'mdi-trash-can-outline'"
                    ></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title v-text="'Delete Analysis'" @click="deleteitem(item)"></v-list-item-title>
                </v-list-item-content>
                </v-list-item>
            </v-list>
            </v-menu>
        </template>
        
        </v-data-table>

        <v-pagination
        @input="paginationChangeHandler"
        v-model="pagination.page"
        :length="pagination.pages"
        circle
        ></v-pagination>

        <show-new-analysis
            :isVisible="isVisible"
            :updateAnalysis="analysis"
            :tableViews="tableViews"
            :showModal="showModal"
            @close="
            showModal = false
            isVisible = null
            "
            @save="saveAnalysis"
        ></show-new-analysis>
        

    </div>
</template>

<script>
import viewsMainMixin from '../../../mixins/tables/Views/viewsMainMixin'
import notifyConfigMixin from '@/mixins/notifyConfigMixin';
import { HEAD_TABLE } from './headTableConfig'
import showNewAnalysis from '@/components/tables/Analysis/dialogs/showNewAnalysis'
import http from '../../../http/axiosConfig';

export default {
    components: {
        showNewAnalysis
    },
    mixins: [viewsMainMixin, notifyConfigMixin],
    name: 'AnalysisTable',
    data() {
        return {
            pagination: {
                page: 1,
                pages: 0,
            },
            isVisible: null,
            key: 0,
            showModal: false,
            search: '',
            headers: HEAD_TABLE,
            items: [],
            analysis: null
        }
    },

    async mounted(){
        this.$nextTick(this.getViews);
        this.getAnalysis();
    },

    methods: {
        paginationChangeHandler(value){
            this.pagination.page = value;
            this.getAnalysis()
        },
        newAnalysy(){
            this.analysis = {};
            this.showModal = true;
        },

        saveAnalysis(){
            this.showModal = false;
            this.getAnalysis();
            this.notifySuccess("Analysis cadastrada com sucesso.");
        },

        async updateAnalysis(item, bool){
            this.analysis = item;
            this.showModal = true

            this.isVisible = bool
        },

        async getAnalysis(){
            let url = `Entity/Analysis`;
            let result = await http.get(`${url}?page=${this.pagination.page}&limit=30`);
            this.pagination.pages = result?.data?.pages;
            this.items = result?.data?.data;
        },

        async deleteitem(item){
            if(await this.$isConfirmeDelete(`Tem certeza que deseja excluir esse item. <strong>${item.description}</strong>`)){
                let url = `Entity/Analysis`;
                let result = await http.delete(url, item.id);

                if(result.data.success == true){
                    this.getAnalysis();
                    this.notifySuccess("Analysis deletada")
                }
            }
        },
    }
}
</script>