<template>
  <v-dialog v-model="modal" scrollable max-width="950px">
    <v-card height="100%">
      <div class="mx-3 mb-3">
        <v-card-actions>
          <v-card-title v-if="!isVisible">Nova Análise</v-card-title>
          <v-card-title
            v-else
            >Visualizar Analysis<span class="font-italic">
              (Somente Leitura)
            </span></v-card-title
          >
          <v-spacer></v-spacer>
          <v-btn text fab color="red" @click="$emit('close')">
            <v-icon large>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-actions class="justyfy-content-end">
          <v-spacer></v-spacer>
          <v-btn color="success" @click="insertAnalysis()"> Aplicar </v-btn>
        </v-card-actions>

        <v-row>
          <v-col class="col-12">
            <v-text-field :readonly="isVisible" label="Name" outlined v-model="newAnalysis.name"></v-text-field>

            <v-text-field :readonly="isVisible" label="Description" outlined v-model="newAnalysis.description"></v-text-field>

            <v-text-field :readonly="isVisible" label="Schema" outlined v-model="newAnalysis.schemaName"></v-text-field>

            <v-autocomplete
              label="View"
              outlined
              :items="tableViews"
              item-text="name"
              item-value="id"
              v-model="newAnalysis.viewId"
              :readonly="isVisible"
            ></v-autocomplete>

            <div>
              <span class="ml-1 font-weight-bold">Parameters</span>
              <span>
                <v-btn
                  class="ma-2"
                  text
                  icon
                  color="green lighten-2"
                  @click="addParams"
                  :readonly="!isVisible"
                >
                  <v-icon>mdi-plus-circle-outline</v-icon>
                </v-btn>
              </span>
            </div>

            <div
              class="form-group"
              v-for="(params, k) in requestParams"
              :key="k"
            >
              <v-row class="mx-1">
                <v-text-field
                  v-model="params.name"
                  label="name"
                  class="form-control mr-2"
                  outlined
                  :readonly="isVisible"
                ></v-text-field>
                <v-text-field
                  v-model="params.dataType"
                  label="dataType"
                  class="form-control"
                  outlined
                  :readonly="isVisible"
                ></v-text-field>
                <v-text-field
                  v-model="params.value"
                  label="value"
                  class="form-control"
                  outlined
                  :readonly="isVisible"
                ></v-text-field>
                <span>
                  <v-btn
                    class="ma-2"
                    text
                    icon
                    color="red lighten-2"
                    @click="removeParams(k)"
                    v-if="!isVisible"
                  >
                    <v-icon>mdi-minus-circle-outline</v-icon>
                  </v-btn>
                </span>
              </v-row>
            </div>

          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import http from '../../../../http/axiosConfig';
export default {
  mixins: [],
    name: 'showNewAnalysis',
    props: ['tableViews', 'showModal', 'updateAnalysis', 'isVisible'],
    watch: {
        showModal(v){
          this.modal = v
        },

        isVisible(v) {
          this.readOnly = v;
          console.log(this.readOnly)
        },

        updateAnalysis: {
          handler(t){
            this.newAnalysis = t;

            if(this.newAnalysis.id){
              this.requestParams = JSON.parse(this.newAnalysis.params);
            } else {
              this.requestParams = []
            }
          },
          deep: true
        }
    },

    data(){
        return {
            readOnly: null,
            modal: false,
            paramsArray: 0,
            requestParams: [],
            newAnalysis: {
                name: '',
                description: '',
                viewId: '',
                schemaName: '',
                params: ''
            },
        }
    },

    methods: {

      async insertAnalysis(){
        this.newAnalysis.params = JSON.stringify(this.requestParams)

        let url = `Entity/Analysis`;
        let result = ''

        if(!this.newAnalysis.id){
          result = await http.post(url, this.newAnalysis);
        } else {
          result = await http.put(url, this.newAnalysis);
        }

        this.newAnalysis = {
          name: '',
          description: '',
          viewId: '',
          params: ''
        }

        if(result.data.success == true){
          this.requestParams = []
          this.$emit('save');
        }

        this.modal = false;
      },

      addParams(){
        if(!this.isVisible){
          this.requestParams.push({
            name: '',
            dataType: '',
            value: ''
          })
        }
      },
      
      removeParams(index){
        if(!this.isVisible){
          this.requestParams.pop(index);
        }
      }
    },

    created(){
    }
}
</script>