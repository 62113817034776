import getViewsMixin from './getViewMixin';

import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism-tomorrow.css'; // import syntax highlighting styles

export default {
  mixins: [getViewsMixin],
  components: {
    PrismEditor,
  },
  methods: {
    async putAtualiza(obj) {
      console.log("obj", obj);
      await this.putViewJson(obj);
      return this.getViews();
    },
    async postAtualiza(item) {
      console.log("item: ", item);
      await this.postViewJson(item);
      return this.getViews();
    },
    async postExecutaView(item) {
      await this.postViewRun(item);
    },
    async deleteAtualiza(item) {
      if(await this.$isConfirmeDelete(`Tem certeza que deseja excluir esse item. <strong>${item.description}</strong>`)){
        await this.deleteView(item);
        return this.getViews();
      }
    },
    async paginationChangeHandler(value) {
      this.pagination.page = await value;
      this.getViews();
    },
    customSort: function (items, index, isDesc) {
      items.sort((a, b) => {
        if (index[0] == 'Created') {
          let created = 'createAt';
          if (!isDesc[0]) {
            return new Date(b[created]) - new Date(a[created]);
          } else {
            return new Date(a[created]) - new Date(b[created]);
          }
        } else if (index[0] == 'LastUpdated') {
          let updated = 'updateAt';
          if (!isDesc[0]) {
            return new Date(b[updated]) - new Date(a[updated]);
          } else {
            return new Date(a[updated]) - new Date(b[updated]);
          }
        }
        else {
          if (typeof a[index] !== 'undefined') {
            if (!isDesc[0]) {
              return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
            }
            else {
              return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
            }
          }
        }
      });
      return items;
    },
    highlighter(code) {
      this.$forceUpdate();
      return highlight(code, languages.text); // languages.<insert language> to return html with markup
    },
  }
};