import notifyConfigMixin from '../../notifyConfigMixin';

export default {
  mixins: [notifyConfigMixin],
  data() {
    return {
      showViewer: false,
      showEdit: false,
      showNew: false,
      count: 0,

      stuffViewer: null,

      // stuff: {
      //   name: null,
      //   icon: null,
      //   description: null,
      //   query: null,
      //   schemaName: null,
      //   parameters: [],
      // },

      stuffEdit: {
        name: null,
        icon: null,
        description: null,
        query: null,
        schemaName: null,
        parameters: [],
      },

      // stuffEdit: null,

      viewNew: {
        name: null,
        icon: null,
        description: null,
        query: null,
        schemaName: null,
        parameters: [],
        javascriptCode: null
      },

      pagination: { pages: 0, rowsPerPage: 1000000000, page: 1 },
    };
  },

  methods: {
    addParams(name) {
      if (name == 'viewNew') {
        this.viewNew.parameters.push({
          name: "", dataType: "", value: ""
        });
      } else if (name == 'stuffEdit') {
        console.log(this.stuffEdit);
        this.stuff.parameters.push({
          name: "", dataType: "", value: ""
        });
      }
    },

    removeParams(name, index) {
      if (name == 'viewNew') {
        console.log(this.viewNew);
        this.viewNew.parameters.splice(index, 1);
        if (this.viewNew.parameters.length == 0) {
          this.viewNew.parameters = [];
        }
      } else if (name == 'stuffEdit') {
        this.stuff.parameters.splice(index, 1);
        if (this.stuff.parameters.length == 0) {
          this.stuff.parameters = [];
        }
      }

    },
    convertDate(date) {
      return new Date(date)
        .toLocaleString('pt-BR')
        .slice(0, 16);
    },
    viewerDialog(item) {
      this.stuffViewer = item;
      this.showViewer = true;
    },
    editDialog(item) {
      this.stuffEdit = item;
      this.showEdit = true;
    },
    handleClose() {
      this.jsonstr = null;
      localStorage.removeItem('NewQuery');
      this.$emit('input', false);
    },
  },
};
