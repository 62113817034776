<template>
    <v-container>
        <v-row class="text-center">
            <v-col md="12">
                <AnalysisTable />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import AnalysisTable from '@/components/tables/Analysis/AnalysisTable';
export default {
    name: 'AnalysisPage',
    components: {
        AnalysisTable
    }
}
</script>